import React from "react"
import { graphql, Link } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Box, Heading, Image, Stack, Text } from "@chakra-ui/react"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default function PageTemplate({ data: { mdx } }) {
  return (
    <Box>
      <SEO
        title={mdx.frontmatter.title}
        image={mdx.frontmatter.banner != "" && mdx.frontmatter.banner}
        description={
          mdx.frontmatter.description != "" && mdx.frontmatter.description
        }
      />
      <Stack
        height="100vh"
        p={["1em", "1em"]}
        spacing={2}
        maxWidth="700px"
        margin="0 auto"
      >
        <Stack spacing={6} paddingBottom={12}>
          <Link to={`/writing`}>
            <Text>⟵ Writing</Text>
          </Link>
          <Box>
            <Heading fontWeight="bold" fontSize="xl">
              {mdx.frontmatter.title}
            </Heading>
            <Text color="darkgray" fontSize="sm">
              {mdx.frontmatter.date}
            </Text>
            {mdx.frontmatter.banner && mdx.frontmatter.banner != "" && (
              <Image
                src={mdx.frontmatter.banner}
                my={3}
                maxHeight="300px"
                width="100%"
                objectFit="cover"
              />
            )}
          </Box>
          <Stack gap={0}>
            <Layout>
              <MDXRenderer>{mdx.body}</MDXRenderer>
            </Layout>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  )
}

export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        banner
        writing
        description
        title
        date
      }
    }
  }
`

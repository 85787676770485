import React from "react"
import { MDXProvider } from "@mdx-js/react"
import {
  Box,
  Heading,
  Link,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react"
import SyntaxHighlighter from "react-syntax-highlighter"
import { ocean } from "react-syntax-highlighter/dist/esm/styles/hljs"

// Define the custom Email component
const Email = props => (
  <Box backgroundColor="pink" padding={2} borderRadius="md">
    {props.children}
  </Box>
)

const shortcodes = {
  h1: props => (
    <Heading size="lg" pt={3}>
      {props.children}
    </Heading>
  ),
  h2: props => (
    <Heading size="md" pt={3}>
      {props.children}
    </Heading>
  ),
  h3: props => (
    <Heading size="sm" pt={3}>
      {props.children}
    </Heading>
  ),
  h4: props => (
    <Heading size="xs" pt={3}>
      {props.children}
    </Heading>
  ),
  p: props => <Text py={1}>{props.children}</Text>,
  // a: Link,
  a: props => (
    <Link
      {...props}
      textDecoration="underline"
      _hover={{ background: "black", color: "white" }}
    >
      {props.children}
    </Link>
  ),
  ul: props => (
    <UnorderedList listStylePos="inside">{props.children}</UnorderedList>
  ),
  li: ListItem,
  code: props => (
    <SyntaxHighlighter
      showLineNumbers
      language="javascript"
      style={ocean}
      wrapLongLines
    >
      {props.children.props.children}
    </SyntaxHighlighter>
  ),
  blockquote: props => (
    <Text fontStyle="italic" paddingLeft={3} borderLeft="2px solid lightgrey">
      {props.children}
    </Text>
  ),
  pre: props => (
    <SyntaxHighlighter
      showLineNumbers
      language="javascript"
      style={ocean}
      wrapLongLines
    >
      {props.children.props.children}
    </SyntaxHighlighter>
  ),
  email: Email,
}

export default function Layout({ children }) {
  return (
    <MDXProvider
      components={shortcodes}
      style={{ fontFamily: "system-ui, sans-serif" }}
    >
      {children}
    </MDXProvider>
  )
}
